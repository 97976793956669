<template>
  <div>
    <van-action-sheet v-model="_QiangGou" title="确认购买">
      <div class="box">
        <div class="container">
          <div 
            :class="['container-item', { focusItem: i == index }]" 
            v-for="(x, i) in qiangGouList" 
            :key="x.id"
            @click="selectTaocan(i, x.id, x.paymentType)">
            <div class="container-item-name">{{ x.name }}</div>
            <div class="container-item-zhifu">
              <span style="font-size: 18px;">{{ x.jine }}</span>
              <span style="font-size: 12px;">/{{ x.danwei }}</span>
            </div>
            <img v-if="i == index" class="container-item-img" src="../img/gou.png" alt="">
            <img v-else class="container-item-img" src="../img/huiYuan.png" alt="">
          </div>
        </div>

        <div class="box-zhifu" v-if="paymentType == '1'">
          <!-- <div class="box-zhifu-item" @click="selectZhifu('1')">
            <img class="box-zhifu-item-img" src="../img/weixin.png" alt="">
            <span style="flex: 1; margin-left: 10px; font-size: 15px;">微信</span>
            <img class="box-zhifu-item-img" src="../img/gou.png" alt="" v-if="wzType == '1'">
            <img class="box-zhifu-item-img" src="../img/huiYuan.png" alt="" v-else>
          </div> -->

          <div class="box-zhifu-item" @click="selectZhifu('2')">
            <img class="box-zhifu-item-img" src="../img/zhifubao.png" alt="">
            <span style="flex: 1; margin-left: 10px; font-size: 15px;">支付宝</span>
            <img class="box-zhifu-item-img" src="../img/gou.png" alt="" v-if="wzType == '2'">
            <img class="box-zhifu-item-img" src="../img/huiYuan.png" alt="" v-else>
          </div>
        </div>

        <div class="box-btn">
          <van-button
            round
            @click="lijiqianggou"
            color="linear-gradient(#FFEBBC, #DAAA52)">立即抢购</van-button>
        </div>
      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import { yangshengZhifu, orderZhifu, zhifubaoPay } from '@/api/Ge/port'
import { fuli_yundian_pay } from '@/common'
import { Toast } from 'vant'
import Loading from './Loading'

export default {
  components: { Loading },
  props: {
    QiangGou: {
      type: Boolean
    },
    qiangGouList: {
      type: Array
    }
  },

  computed: {
    _QiangGou: {
      get () {
        return this.QiangGou
      },
      set () {
        this.index = -1
        this.paymentType = ''
        this.wzType = ''
        this.$emit('closeQiangGou')
      }
    }
  },

  data () {
    return {
      index: -1,
      id: '',
      loading: false,
      paymentType: '',
      wzType: '', // 1 微信 2 支付宝
    }
  },

  methods: {
    lijiqianggou () {
      if (this.index == -1) {
        Toast('请选择套餐')
        return
      }
      if (this.paymentType == '1') {
        if (!this.wzType) {
          Toast('请选择支付方式')
          return
        }
      }
      this.loading = true
      yangshengZhifu({ id: this.id, payType: this.wzType }).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          if (this.paymentType == '1') {
            if (this.wzType == '1') {
              this.weixinPay(res.data.data.id, '1')
            } else if (this.wzType == '2') {
              this.zhifubaoPay(res.data.data.id)
            }
          } else if (this.paymentType == '0') {
            this.orderZhifu(res.data.data.id)
          }
          this._QiangGou = false
        } else {
          this.loading = false
          Toast(res.data.message)
        }
      }).catch(() => {
        this.loading = false
      })
    },

    selectTaocan (i, id, paymentType) {
      this.index = i
      this.id = id,
      this.paymentType = paymentType
    },

    weixinPay (id, payType) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.wechatPay.postMessage(`id=${id}&requesetType=20&payType=${payType}&session=${this.$route.query.session}`)
      }
      if (isAndroid) {
        window.android.wxpay(`id=${id}&requesetType=20&payType=${payType}&session=${this.$route.query.session}`)
      }
    },

    zhifubaoPay (id) {
      zhifubaoPay({ id, payType: '2' }).then(res => {
        if (res.data.code === 0) {
          fuli_yundian_pay(res.data, '2')
        }
      })
    },

    orderZhifu (id) {
      orderZhifu({ id, payType: '0' }).then(res => {
        if (res.data.code === 0) {
          this.$parent.paySuccessBoolean = true
        } else {
          this.loading = false
          Toast(res.data.message)
        }
      }).catch(() => {
        this.loading = false
      })
    },

    selectZhifu (wzType) {
      this.wzType = wzType
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 310px;
  &-zhifu {
    margin: 15px 0;
    &-item {
      display: flex;
      align-items: center;
      margin-block: 15px;
      &-img {
        width: 23px;
      }
    }
  }
  &-btn {
    .van-button {
      width: 100%;
      .van-button__text {
        color: #975018;
      }
    }
  }
}
.container {
  display: flex;
  overflow: auto;
  &-item {
    flex-shrink: 0;
    width: 38%;
    border: 1px solid #7F4DDF;
    padding: 10px;
    aspect-ratio: 1/0.8;
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &-name {
      width: 100%;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 5px;
    }
    &-zhifu {
      color: #7F4DDF;
      &-img {
        width: 23px;
      }
    }
    &-img {
      width: 15px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .focusItem {
    background: #f4f0fc;
  }
}
</style>