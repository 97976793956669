<template>
  <div class="container">
    <van-nav-bar 
      fixed 
      @click-left="onClickLeft" 
      left-arrow />

    <img src="./img/title.png" alt="">

    <div class="taocan">
      <div class="qiangGou">
        <van-swipe
          :width="8 * bei"
          :loop="false"
          :show-indicators="false"
          @change="swipeChange">
          <van-swipe-item v-for="x in taocan" :key="x.id">
            <div class="qiangGou-item" :style="{ width: `${7.6533 * bei}px`, height: `${12.2133 * bei}px`}">
              <p class="qiangGou-item-title">{{ x.name }}</p>

              <div class="qiangGou-item-main yangshengContent">
                <div class="yangshengContent-price">
                  <div class="yangshengContent-price-xianPrice">
                    <span>{{ x.jine }}</span>
                    <span style="font-size: 15px;">/{{ x.danwei }}</span>
                  </div>
                  <p class="yangshengContent-price-yuanPrice">原价：{{ x.originalPrice }}</p>
                </div>

                <div class="yangshengContent-main" :style="{ maxHeight: `${5.3333 * bei}px` }">
                  <div class="yangshengContent-main-item" v-if="x.healthSetMealVOS.product.length">
                    <div class="yangshengContent-main-item-name">
                      <img style="width: 18px; height: 18px; margin-right: 5px;" src="./img/chanpin.png" alt="">
                      <span style="font-size: 12px; font-weight: bold;">产品</span>
                    </div>

                    <div class="yangshengContent-main-item-list">
                      <div class="yangshengContent-main-item-list-item" v-for="y in x.healthSetMealVOS.product" :key="y.goodsId">
                        <p class="yangshengContent-main-item-list-item-name">{{ y.goodsName }}</p>
                        <p>x{{ y.number + y.units }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="yangshengContent-main-item" v-if="x.healthSetMealVOS.service.length">
                    <div class="yangshengContent-main-item-name">
                      <img style="width: 18px; height: 18px; margin-right: 5px;" src="./img/serve.png" alt="">
                      <span style="font-size: 12px; font-weight: bold;">服务</span>
                    </div>

                    <div class="yangshengContent-main-item-list">
                      <div class="yangshengContent-main-item-list-item" v-for="y in x.healthSetMealVOS.service" :key="y.goodsId">
                        <p class="yangshengContent-main-item-list-item-name">{{ y.goodsName }}</p>
                        <p>x{{ y.number + y.units }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="yangshengContent-footer">
                  <van-button
                    round
                    @click="OneGouNow(x)"
                    color="linear-gradient(#FFEBBC, #DAAA52)">立即抢购</van-button>
                </div>
              </div>

              <p class="qiangGou-item-youxiao">有效期{{ x.time }}{{ x.timeDanwei }}</p>

              <img class="qiangGou-item-img" src="./img/line.png" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="fuwenben">
        <div class="fuwenben-imgs">
          <img class="fuwenben-imgs-img" src="./img/jieshao.png" alt="">
        </div>

        <div v-for="(x, i) in described" :key="i">
          <div v-html="x" v-if="i == swipeIndex"></div>
        </div>
      </div>
    </div>
    
    <van-goods-action>
      <van-goods-action-icon icon="service-o" text="客服" @click="contact" />
      <van-goods-action-button
        type="danger"
        text="立即抢购"
        color="linear-gradient(#FFEBBC, #DAAA52)"
        @click="goumai"/>
    </van-goods-action>

    <qiang-gou 
      :QiangGou="QiangGou"
      :qiangGouList="qiangGouList"
      @closeQiangGou="closeQiangGou" />

    <one-gou 
      :OneGou="OneGou"
      :OneGouList="OneGouList"
      @closeOneGou="closeOneGou" />

    <pay-success 
      :paySuccessBoolean="paySuccessBoolean"
      @closepaySuccessBoolean="closepaySuccessBoolean" />
  </div>
</template>

<script>

import { getTaocan } from '@/api/Ge/port'
import PaySuccess from './components/PaySuccess'
import QiangGou from './components/QiangGou'
import OneGou from './components/OneGou'
import { Toast } from 'vant'
import { contact } from "@/common"

export default {
  components: { QiangGou, OneGou, PaySuccess },

  data () {
    return {
      taocan: [],
      described: [],
      qiangGouList: [],
      OneGouList: [],
      QiangGou: false,
      OneGou: false,
      paySuccessBoolean: false,
      swipeIndex: 0,
      accid: [],
      bei: 1
    }
  },

  created () {
    this.$store.commit('setSession', this.$route.query.session)
    this.getPage()
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    let bei = screenWidth / 10
    this.bei = bei
    window.paySuccess = this.paySuccess
  },

  methods: {
    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    goumai () {
      if (this.qiangGouList.length) {
        this.QiangGou = true
      } else {
        Toast('暂无套餐')
      }
    },

    OneGouNow (x) {
      let OneGouList = [{
        id: x.id,
        name: x.name,
        jine: x.paymentType == '1' ? x.price : x.postHorse,
        danwei: x.paymentType == '1' ? '元' : '匹',
        paymentType: x.paymentType
      }]
      this.OneGouList = OneGouList
      this.OneGou = true
    },
    
    closeQiangGou () {
      this.QiangGou = false
    },

    closeOneGou () {
      this.OneGou = false
    },

    closepaySuccessBoolean () {
      this.paySuccessBoolean = false
      this.onClickLeft()
    },

    paySuccess () {
      this.paySuccessBoolean = true
    },

    swipeChange (swipeIndex) {
      this.swipeIndex = swipeIndex
    },

    contact () {
      let accid  = this.accid[this.swipeIndex]
      if (accid) {
        contact(accid)
      } else {
        Toast('暂无客服')
      }
    },

    getPage () {
      getTaocan().then(res => {
        let described = []
        let accid = []
        const qiangGouList = res.data.data.map(x => ({ 
          id: x.id,
          name: x.name,
          jine: x.paymentType == '1' ? x.price : x.postHorse,
          danwei: x.paymentType == '1' ? '元' : '匹',
          paymentType: x.paymentType
        }))
        this.qiangGouList = qiangGouList
        res.data.data.forEach(x => {
          described.push(x.described)
          if (x.shopInfo.imAccount) {
            accid.push(x.shopInfo.imAccount.accid)
          } else {
            accid.push('')
          }
          x.timeDanwei = x.expiration == '0' ? '年' : x.expiration == '1' ? '月' : '日'
          x.danwei = x.paymentType == '1' ? '元' : '匹'
          x.jine = x.paymentType == '1' ? x.price : x.postHorse
        })
        this.described = described
        this.accid = accid
        this.taocan = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: transparent;
  min-height: 100%;
  background: #f6f6f6;
  padding-bottom: 50px;
  box-sizing: border-box;
}
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  background: transparent;
  .van-nav-bar__left,
  .van-nav-bar__right {
    padding: 0 15px;
  }
  .van-nav-bar__title,
  .van-icon-arrow-left {
    color: #000;
  }
}
.van-goods-action {
  /deep/ .van-button__text {
    color: #975018;
  }
}
.taocan {
  background: linear-gradient(#fff, #bb9bfa);
  border-radius: 10px;
  transform: translateY(-20px);
  padding: 15px 10px;
  box-sizing: border-box;
}
.qiangGou {
  margin-bottom: 15px;
  &-item {
    flex-shrink: 0;
    border-radius: 10px;
    background: linear-gradient(#c3b1f3, #ac8af1);
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &-title {
      width: 83%;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-main {
      flex: 1;
      border-radius: 10px;
      background: linear-gradient(#E9DEFF, #fff 14% 82.7%, #E9DEFF);
      padding: 55px 15px 15px;
      margin: 5px 0;
      box-sizing: border-box;
    }
    &-youxiao {
      color: #fff;
      font-size: 12px;
    }
    &-img {
      width: 40%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .yangshengContent {
      display: flex;
      flex-direction: column;
      &-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-xianPrice {
          color: #7F4DDF;
          font-size: 28px;
        }
        &-yuanPrice {
          color: #999;
          font-size: 12px;
          text-decoration: line-through;
        }
      }
      &-main {
        flex: 1;
        margin: 10px 0;
        overflow: auto;
        &-item {
          &-name {
            display: flex;
            align-items: center;
            background: #F6F6F6;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
          }
          &-list {
            &-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 12px;
              padding: 5px 10px;
              box-sizing: border-box;
              margin-bottom: 5px;
              &-name {
                font-weight: bold;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
      &-footer {
        padding: 0 10px;
        .van-button {
          width: 100%;
          height: 40px;
          .van-button__text {
            color: #975018;
          }
        }
      }
    }
  }
}
.fuwenben {
  border-radius: 10px;
  background: #fff;
  padding: 0 15px 20px;
  &-imgs {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    &-img {
      width: 4.2703rem;
      height: 0.9189rem;
    }
  }
}
</style>